// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import API from './API';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(null);
  const [showrunner, setShowrunner] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAuth = async () => {
      try {
        const res = await API.getAuth();
        if (!res.data.anonymous) {
          setAuth(res.data.user);
          setShowrunner(res.data.showrunner);
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };

    fetchAuth();
  }, []);

  return (
    <AuthContext.Provider value={{ auth, showrunner, isAuthenticated, setAuth, loading }}>
      {children}
    </AuthContext.Provider>
  );
};