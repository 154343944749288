import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Button from "../../../components/buttons/Button";

// import TicketManager from "../../../components/showrunner/TicketManager";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../utils/authContext";
import Tabular from "../../../components/Tabular";
import Discounts from "./Discounts";
import EventDetails from "./EventDetails";
import TicketManager from "./TicketManager";

import utils from "../../../utils/utils";
import API from "../../../utils/API";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

let globalShowID = 0

export default function EventManager(props) {
	const { showrunner } = useAuth();

	const history = useHistory();

	const initSeatState = {
		selectedSeats: {},
		seatClickTracker: {},
		seatIndex: {}
	}


	const initEvent = {
		production: {}
	}
	const [show, setShow] = useState({})

	const [showID, setShowID] = useState(0)
	const [prod, setProd] = useState({})
	const [event, setEvent] = useState(initEvent)
	const [canPublish, setCanPublish] = useState(false)
	const [publishChecklist, setPublishChecklist] = useState({
		show_details: false,
		banking_set_up: false,
		category_minimum: false,
		ticket_minimum: false
	})
	const [bankingIsSetUp, setBankingIsSetUp] = useState(false)
	const [showDetails, setShowDetails] = useState(false)
	const [categoryMinimum, setCategoryMinimum] = useState(false)
	const [ticketMinimum, setTicketMinimum] = useState(false)
	const [publishModalOpen, togglePublishModal] = useState(false)
	const [pauseModalOpen, togglePauseModal] = useState(false)

	const location = useLocation();

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const showID = params.get("showID");
		setShowID(showID);
		globalShowID = showID
		if (!showrunner) return
		const showrunnerID = showrunner.id
		resetEvent(showID, showrunnerID)

		// Show alert message if present in the state
		if (location.state && location.state.alertMessage) {
			utils.sendAlert(location.state.alertMessage, location.state.alertType);
			// Clear the state to ensure the alert only shows once
			history.replace({
				...location,
				state: {}
			});
		}
	}, [location, showrunner]);

	useEffect(() => {
		if (!showrunner) return
		if (bankingIsSetUp && showDetails && categoryMinimum && ticketMinimum) {
			setCanPublish(true)
		}
	}, [bankingIsSetUp, showDetails, categoryMinimum, ticketMinimum])

	const tabs = [
		{ name: "Event Details", component: <EventDetails reset={resetEvent} showID={showID} /> },
		{ name: "Manage Tickets", component: <TicketManager reset={resetEvent} showrunner={showrunner} /> },
		{ name: "Discount Codes", component: <Discounts showID={globalShowID} /> },
	];

	function handleBackButtonClick() {
		history.goBack();  // This will take you back to the previous page
	}

	function checkEventDetails(ev) {
		if (!ev.venue || !ev.location || !ev.date || !ev.production.description || !ev.production.poster_portrait) {
			setShowDetails(false)
		} else {
			setShowDetails(true)

		}
	}

	function resetEvent(id, showRunnerID) {
		API.getShow(id)
			.then(res => {
				setEvent(res.data)
				checkEventDetails(res.data)
			})
		if (showRunnerID) {
			API.getShowManage(id, showRunnerID)
				.then(res => {
					const { can_publish_checklist } = res.data
					const { banking_set_up, category_minimum, ticket_minimum } = can_publish_checklist
					setBankingIsSetUp(banking_set_up)
					setCategoryMinimum(category_minimum)
					setTicketMinimum(ticket_minimum)
					checkEventDetails(res.data)
				});
		}
	}

	function updateShow(field, value) {
		if (!canPublish) return
		const updatedShow = { ...event, [field]: value }
		setShow(updatedShow)
		API.updateShow(showID, updatedShow)
			.then(res => {
				utils.sendAlert("Show Updated", "success");
				resetEvent(showID, showrunner.id);
				togglePublishModal(false);
				togglePauseModal(false);
			})
	}
	return (
		<>
			{/* <Button buttonProps={{ onClick: handleBackButtonClick }}>Go Back</Button> */}
			<div>
				<div className="event-manager__banner">
					<img src={event.production.poster_portrait} alt="event poster" className="event-manager__poster" />
					<div className="event-manager__info">
						<h1 className="h__1">
							{prod.name}
						</h1>
						<p className="subtext u-mgn-btm-sm">{utils.formatDateTime(event.date)[0]} at {utils.formatDateTime(event.date)[1]}</p>
						<p className="subtext u-mgn-btm-sm">Venue: {event.venue}</p>
						<p className="subtext u-mgn-btm-sm">Address: {event.location}</p>
						<p className="subtext u-mgn-btm-sm ">Event URL: <a href={`/event/${showID}`} target="_blank" className="u-link--simple">{`https://slick-tix.org/event/${showID}`}</a></p>
						<div className="event-manager__live">
							{event.published ?
								<div className="u-flex">
									<div className="u-mgn-right-sm">
										<Button buttonProps={{ onClick: () => togglePauseModal(true) }}>Pause Event</Button>
									</div>
									{/* <Button buttonProps={{ onClick: () => togglePublishModal(true) }}>Cancel Event</Button> */}
								</div>
								:
								<Button buttonProps={{ onClick: () => togglePublishModal(true) }} action={true}>Go Live</Button>
							}
						</div>
					</div>
				</div>

			</div>
			<div className="event-manager__tabs">
				<Tabular tabs={tabs} />
			</div>

			<div className="u-flex">

				<div className="showrunner__seat-manage"></div>
			</div>



			<Modal isOpen={publishModalOpen} toggle={() => togglePublishModal(!publishModalOpen)}>
				<ModalHeader>
					Publish Event
					<div className="modal__close" onClick={() => togglePublishModal(false)}>&#x2715;</div>
				</ModalHeader>
				<ModalBody>
					<div className="event-manager__publish">

						{canPublish ?
							<p className="p__1">Are you sure you want to publish this event? This will make it available for purchase by customers.</p>
							:
							<>
								<p className="p__1 u-mgn-btm-sm">
									You must complete the following before you can publish this show:
								</p>
								<div className="event-manager__publish__checklist">
									<div className="event-manager__publish__checklist-item">
										<p className="p__2 u-mgn-btm-sm">Set up your banking details</p>
										<span>
											{bankingIsSetUp ?
												<div className="checklist-icon yes">
													<FontAwesomeIcon icon={faCheck} />
												</div>
												:
												<div className="checklist-icon no">
													<FontAwesomeIcon icon={faTimes} />
												</div>
											}
										</span>
									</div>
									<div className="event-manager__publish__checklist-item">
										<p className="p__2 u-mgn-btm-sm">Set at least one ticket category </p>
										<span>
											{categoryMinimum ?
												<div className="checklist-icon yes">
													<FontAwesomeIcon icon={faCheck} />
												</div>
												:
												<div className="checklist-icon no">
													<FontAwesomeIcon icon={faTimes} />
												</div>
											}
										</span>
									</div>
									<div className="event-manager__publish__checklist-item">
										<p className="p__2 u-mgn-btm-sm">Set at least one ticket </p>
										<span>
											{ticketMinimum ?
												<div className="checklist-icon yes">
													<FontAwesomeIcon icon={faCheck} />
												</div>
												:
												<div className="checklist-icon no">
													<FontAwesomeIcon icon={faTimes} />
												</div>
											}
										</span>
									</div>
									<div className="event-manager__publish__checklist-item">
										<p className="p__2 u-mgn-btm-sm">Set show details </p>
										<span>
											{showDetails ?
												<div className="checklist-icon yes">
													<FontAwesomeIcon icon={faCheck} />
												</div>
												:
												<div className="checklist-icon no">
													<FontAwesomeIcon icon={faTimes} />
												</div>
											}
										</span>
									</div>
								</div>
							</>
						}
						<Button className="u-mgn-top-sm" buttonProps={{ onClick: () => updateShow("published", true) }} disabled={!canPublish}>Publish</Button>
					</div>
				</ModalBody>
			</Modal>

			<Modal isOpen={pauseModalOpen} toggle={() => togglePauseModal(!publishModalOpen)}>
				<ModalHeader>
					Pause Event
					<div className="modal__close" onClick={() => togglePauseModal(false)}>&#x2715;</div>
				</ModalHeader>
				<ModalBody>
					<p className="p__1">By clicking the button below, your event will no longer be accessible to customers. You can republish at any time until the event date has passed.</p>
					<Button className="u-mgn-top-sm" buttonProps={{ onClick: () => updateShow("published", false) }}>Pause</Button>
				</ModalBody>
			</Modal>
		</>
	)
}