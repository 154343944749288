import React from "react";


export default function Input({ inputProps, className, price, type, containerClass, label=false }) {
    return (
        <div className={`input__container ${containerClass}`}>
            {label ? <label className="input__label">{label}</label> : null}
            {type === 'file' ? 
                <input type="file" className={`input ${className ? className : ""}`} {...inputProps} />
            :
                <input className={`input ${className ? className : ""} ${price ? "u-pad-left-sm" : ""}`} {...inputProps} step={price ? "0.01" : ""}/>
            }
            {price && type !== 'file' ?
                <span className="input__price">$</span>
            : null}
        </div>
    )
}
