import React, { useEffect, useState } from "react";
import API from "../../utils/API";
import ShowCard from "../../components/cards/ShowCard";
import 'react-quill/dist/quill.snow.css';
import { Link } from "react-router-dom";
// import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useAuth } from '../../utils/authContext';
import utils from "../../utils/utils";

export default function Events(props) {
	// const history = useHistory();
	const { showrunner } = useAuth();

	const [events, setEvents] = useState([]);

	useEffect(() => {
		if (!showrunner) return;
		reset();
	}, [showrunner])

	function reset() {
		if (showrunner.id) {
			const params = API.objectToParams({ showrunner_id: showrunner.id })
			API.getShows(params)
				.then(res => {
					console.log("shows", res.data)
					setEvents(res.data);
				})
		}
	}

	function deleteShow(showID) {
		API.deleteShow(showID)
			.then(res => {
				console.log("deleted show", res.data)
				utils.sendAlert("Event deleted", "success")
				reset();
			})
			.catch(err => {
				console.log("err", err)
				utils.sendAlert("Error deleting event", "error")
			})
	}

	// function handleBackButtonClick() {
	// 	history.goBack();  // This will take you back to the previous page
	// }


	return (
		<>
			<div className="showrunner__prods">
					<Link to="/showrunner/events/create">
						<ShowCard createNew={true} addCb={() => console.log("create new event")} />
					</Link>
				{events.map(ev => (
						<ShowCard key={ev.id} show={ev} createNew={false} deleteCb={deleteShow}/>
				))}
			</div>

		</>
	)
}

