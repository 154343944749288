import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

export default function Tabular({ tabs }) {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const initialTab = params.get("tab") || tabs[0].name;

  const [activeTab, setActiveTab] = useState(initialTab);
  const [activeComponent, setActiveComponent] = useState(
    tabs.find((tab) => tab.name === initialTab).component
  );

  useEffect(() => {
    const tab = params.get("tab") || tabs[0].name;
    setActiveTab(tab);
    setActiveComponent(tabs.find((t) => t.name === tab).component);
  }, [location]);

  const handleTabChange = (tab) => {
    setActiveTab(tab.name);
    setActiveComponent(tab.component);
    params.set("tab", tab.name);
    history.push({ search: params.toString() });
  };

  return (
    <div className="tabular">
      <div className="tabular__tabs">
        {tabs.map((tab) => (
          <div
            key={tab.name}
            className={`tabular__tab ${
              tab.name === activeTab ? "tabular__tab--active" : ""
            }`}
            onClick={() => handleTabChange(tab)}
          >
            <div className="tabular__link">{tab.name}</div>
          </div>
        ))}
      </div>
      <div className="tabular__content">{activeComponent}</div>
    </div>
  );
}