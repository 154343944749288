import React, { useEffect, useState } from "react";
import { useParams, useHistory } from 'react-router-dom';
import Input from "../components/Input";
import Button from "../components/buttons/Button";
import API from "../utils/API";

export default function ResetPassword(props) {

  const { uidb64, token } = useParams(); 
  const [password, setPassword] = useState("");

  const handleChange = (e) => {
    setPassword(e.target.value);
  }

  const resetPassword = (e) => {
    e.preventDefault();
    const data = {
      password: password
    }
    API.resetPass(uidb64, token, {password})
      .then(res => {
        console.log("reset password res", res)
      })
  }

  return (
    <div>
      <h1>Reset Password</h1>

      <form>
        <Input inputProps={{ type: "password", name: "password", id: "password", placeholder: "Password", onChange: handleChange }} />
        <Button buttonProps={{ onClick: resetPassword }}>Reset Password</Button>
      </form>
    </div>
  )
}