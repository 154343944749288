import React, { useEffect, useState } from "react";
import Sidenav from "../components/Sidenav";
import { ReactComponent as Ticket } from "../assets/img/SVG/ticket.svg"
import { ReactComponent as Credit } from "../assets/img/SVG/credit.svg"
import { ReactComponent as Users } from "../assets/img/SVG/users.svg"
import { ReactComponent as User } from "../assets/img/SVG/user.svg"
import { ReactComponent as HomeIcon } from "../assets/img/SVG/home.svg"
import { ReactComponent as ProfileIcon } from "../assets/img/SVG/profile.svg"
import { ReactComponent as AdminIcon } from "../assets/img/SVG/admin.svg"
import Navbar from "../components/Navbar";
import API from "../utils/API";
import {
	Switch,
	Route,
} from "react-router-dom";
import { useAuth } from '../utils/authContext';

import Events from "./ShowRunner/Events";
import Financial from "./ShowRunner/Financial";
import Orders from "./ShowRunner/Orders";
import EventManager from "./ShowRunner/EventManager/EventManager";
import Home from "./ShowRunner/Home";
import Profile from "./ShowRunner/Profile";
import CreateEvent from "./ShowRunner/EventManager/CreateEvent";
import Slickfilliates from "./ShowRunner/Slickfilliates";
import Admin from "./ShowRunner/Admin";


export default function ShowRunner() {
	const { isAuthenticated, loading, showrunner, auth } = useAuth();
	const initTabs = [
		{
			name: "Home",
			link: "/showrunner/home",
			icon: <HomeIcon />,
			urlName: "home"
		},
		{
			name: "Profile",
			link: "/showrunner/profile",
			icon: <User />,
			urlName: "profile"
		},
		{
			name: "Events",
			link: "/showrunner/events",
			icon: <Ticket />,
			urlName: "events"
		},
		{
			name: "Financial",
			link: "/showrunner/financial",
			icon: <Credit />,
			urlName: "financial"
		},
		{
			name: "Orders",
			link: "/showrunner/orders",
			icon: <ProfileIcon />,
			urlName: "orders"
		},

	]

	const [tabs, setTabs] = useState(initTabs);

	const initState = {
		seatData: {},
		readyForCheckout: false,
		showID: 0,
		loading: true,
		showDate: ""
	}


	useEffect(() => {
		if (loading) return;
		if (!isAuthenticated) {
			window.location.href = "/"
		}
		console.log("showrunner", showrunner)
		if (showrunner && showrunner.slickfilliate) {
			const tabExists = tabs.some(tab => tab.name === "Referrals");
			if (!tabExists) {
				setTabs([...tabs, {
					name: "Referrals",
					link: "/showrunner/slickfilliates",
					icon: <Users />,
					urlName: "slickfilliates"
				}]);
			}

		}
		console.log("Auth", auth)
		if (auth && auth.is_staff) {
			const adminTabExists = tabs.some(tab => tab.name === "Admin");
			if (!adminTabExists) {
				setTabs([...tabs, {
					name: "Admin",
					link: "/showrunner/slicktix",
					icon: <AdminIcon />,
					urlName: "slicktix"
				}]);
			}
		}

	}, [isAuthenticated])

	return (
		<div className="showrunner__page">
			<Sidenav items={tabs} activeTab={window.location.href.split("showrunner/")[1]} />

			<div className="showrunner__container">
				<Navbar logo={false} />
				<div className="showrunner">
					<Switch>
						<Route path="/showrunner/home">
							<Home />
						</Route>
						<Route path="/showrunner/profile">
							<Profile />
						</Route>
						<Route path="/showrunner/show">
							<>
								<EventManager />
							</>
						</Route>
						<Route path="/showrunner/events/create">
							<CreateEvent />
						</Route>

						<Route path="/showrunner/events">
							<Events />
						</Route>
						{/* FINANCIAL */}
						<Route path="/showrunner/financial">
							<Financial />
						</Route>
						{/* ORDERS */}
						<Route path="/showrunner/orders">
							<Orders />
						</Route>
						{/* Slickfilliates */}
						<Route path="/showrunner/slickfilliates">
							<Slickfilliates />
						</Route>
						{/* Admin*/}
						<Route path="/showrunner/slicktix">
							<Admin />
						</Route>
						{/* CUSTOMERS */}
						{/* <Route path="/showrunner/customers">
							<Customers auth={authState}/>
						</Route> */}
					</Switch>
				</div>
			</div>
		</div>
	)
}