import React, { useEffect, useState } from "react";
import API from "../../utils/API";
import Input from "../../components/Input";
import Button from "../../components/buttons/Button";
import { useAuth } from '../../utils/authContext';
import utils from "../../utils/utils";

export default function Profile() {
  const { showrunner,  auth } = useAuth();

  const [profile, setProfile] = useState({
    company: "",
    email: "",
    first_name: "",
    last_name: ""
  });
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (!auth || !showrunner) return;
    const { company, email, first_name, last_name, username } = showrunner;
    setUsername(username);
    setProfile({ company, email, first_name, last_name })
  }, [auth, showrunner]);

  function handleChange(e) {
    const { name, value } = e.target;
    setProfile(prev => ({ ...prev, [name]: value }))
  }

  function updateProfile() {
    API.updateProfile(showrunner.id, { ...profile, username })
      .then(res => {
        utils.sendAlert("Profile Updated", "success")
      })
      .catch(err => {
        utils.sendAlert("Error updating profile. Contact us at info@slick-tix.org if the issue persists.", "error")
      })
  }


  return (
    <div>
      <h1>Profile Settings</h1>
      <div >
        <div className="">
          <Input inputProps={{ onChange: e => setUsername(e.target.value), value: username, name: "username" }} label="Username"/>
        </div>
      </div>

      <div>
        <div className="">
          <Input inputProps={{ onChange: handleChange, value: profile.first_name, name: "first_name" }} label="First Name"/>
        </div>
      </div>

      <div>
        <div className="">
          <Input inputProps={{ onChange: handleChange, value: profile.last_name, name: "last_name" }} label="Last Name" />
        </div>
      </div>

      <div>
        <div className="">
          <Input inputProps={{ onChange: handleChange, value: profile.email, name: "email" }}  label="Email - Your customers will be able to reach out to you at this email address."/>
        </div>
      </div>

      <div>
        <div className="">
          <Input inputProps={{ onChange: handleChange, value: profile.company, name: "company" }} label="Company (optional - will display on event pages if name is provided)."/>
        </div>
      </div>

      <Button buttonProps={{ onClick: updateProfile }}>Update Profile</Button>
    </div>
  )
} 