import React, { useEffect, useState } from "react";
import { Table, TableBody, TableHead, TableRow, TH, TD } from "../../components/Table";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Input from "../../components/Input";
import utils from "../../utils/utils";
import Button from "../../components/buttons/Button";
import API from "../../utils/API";
import { useAuth } from '../../utils/authContext';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import moment from 'moment';
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

export default function Orders(props) {
	const { showrunner, loading, auth } = useAuth();

	const [refundModalOpen, toggleRefundModal] = useState(false);
	const [activeOrder, setActiveOrder] = useState({});
	const [refund, setRefund] = useState({});
	const [orders, setOrders] = useState([]);
	const [filteredOrders, setFilteredOrders] = useState([]);
	const [refundProcessing, setRefundProcessing] = useState(false);
	const [startDate, setStartDate] = useState(moment().subtract(1, 'week'));
	const [endDate, setEndDate] = useState(moment());
	const [selectedEvent, setSelectedEvent] = useState(0);
	const [events, setEvents] = useState([]);
	const [focusedInput, setFocusedInput] = useState(null);
	const [allTime, setAllTime] = useState(true);
	const [sortColumn, setSortColumn] = useState(null);
	const [sortOrder, setSortOrder] = useState('asc');

	useEffect(() => {
		if (loading) return;
		resetOrders();
		if (!showrunner) return;
		const params = API.objectToParams({ showrunner_id: showrunner.id })
		API.getShows(params)
			.then(res => {
				setEvents(res.data);
			});
	}, [auth]);

	useEffect(() => {
		if (!showrunner) return;
		applyFilters();
	}, [endDate, selectedEvent, allTime]);

	function resetOrders() {
		const filters = { showrunnerID: showrunner.id };
		if (startDate && !allTime) filters.startDate = startDate.format('YYYY-MM-DD');
		if (endDate && !allTime) filters.endDate = endDate.format('YYYY-MM-DD');
		if (selectedEvent > 0) filters.showID = selectedEvent;
		API.getRecentOrders(filters.showrunnerID, filters.limit, filters.days, filters.startDate, filters.endDate, filters.showID)
			.then(res => {
				setOrders(res.data);
				setFilteredOrders(res.data);
			});
	}

	function handleRefundChange(e) {
		const { name, value } = e.target;
		if (name === "refund_amount") {
			if (value > activeOrder.total) {
				return;
			}
		}
		setRefund({ ...refund, [name]: value });
	}

	function processRefund() {
		const req = { refund_amount: (refund.refund_amount * 100), orderID: activeOrder.id, payment_intent_id: activeOrder.payment_id, stripe_connect_id: showrunner.stripe_connect_id };
		utils.sendAlert("Processing refund...", "success");
		API.refundOrder(req)
			.then(res => {
				toggleRefundModal(false);
				if (res.data.error) {
					utils.sendAlert("There was an issue processing this refund. If the issue persists, please contact us at info@slick-tix.org.", "error");
					return;
				} else {
					utils.sendAlert("Refund processed successfully", "success");
					resetOrders();
				}
			});
	}

	function downloadEmails(query, ID, name = null) {
		if (query === "productionID") {
			for (let i = 0; i < showrunner.productions.length; i++) {
				if (showrunner.productions[i].name === name) {
					ID = showrunner.productions[i].id;
				}
			}
		}
		if (query === "showID") {
			console.log("id", ID);
		}
		API.getCustomerEmails({
			showrunner_id: showrunner.id,
			start_date: !allTime && startDate ? startDate.format('YYYY-MM-DD') : null,
			end_date: !allTime && endDate ? endDate.format('YYYY-MM-DD') : null,
			show_id: selectedEvent > 0 ? selectedEvent : null,
		})
			.then(res => {
				if (res.data && res.data.download_link) {
					const link = document.createElement('a');
					link.href = res.data.download_link;
					link.setAttribute('download', 'customers.csv');
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				} else {
					console.error("Download link not found in the response.");
				}
			})
			.catch(error => {
				console.error("Error fetching customer emails:", error);
			});
	}

	function filterOrders(e) {
		const { value } = e.target;
		const filtered = orders.filter(order => {
			const nameMatch = order.full_name && order.full_name.toLowerCase().includes(value.toLowerCase());
			const emailMatch = order.email && order.email.toLowerCase().includes(value.toLowerCase());
			return nameMatch || emailMatch;
		});
		setFilteredOrders(value === "" ? orders : filtered);
	}

	const handleEventChange = (event) => {
		setSelectedEvent(event.target.value);
	};

	const applyFilters = () => {
		resetOrders();
	};

	const handleSort = (column) => {
		let order = 'asc';
		if (sortColumn === column && sortOrder === 'asc') {
			order = 'desc';
		}
		setSortColumn(column);
		setSortOrder(order);
		const sortedOrders = [...filteredOrders].sort((a, b) => {
			if (order === 'asc') {
				return a[column] > b[column] ? 1 : -1;
			} else {
				return a[column] < b[column] ? 1 : -1;
			}
		});
		setFilteredOrders(sortedOrders);
	};

	const handleCopy = (text) => {
		navigator.clipboard.writeText(text);
		utils.sendAlert("Copied to clipboard", "success");
	};

	return (
		<>
			<div className="showrunner__orders">
				<div className="showrunner__orders__header" >
					<h3 className="heading__3">Orders</h3>
					<div>
						<Button buttonProps={{ onClick: downloadEmails }}>Download all emails for these orders</Button>
					</div>
				</div>
				<div className="showrunner__filters">
					<div className="showrunner__filters--search">
						<Input className="showrunner__filters--search" inputProps={{ placeholder: "Search names and email addresses", onChange: filterOrders }} />
					</div>
					<div className="showrunner__filters--event">

						<select value={selectedEvent} onChange={handleEventChange}>
							<option value={0}>All events</option>
							{events.map(ev => (
								<option key={ev.id} value={ev.id}>{ev.production.name}, {utils.formatDateTime(ev.date)[0]} at {utils.formatDateTime(ev.date)[1]}</option>
							))}
						</select>
					</div>

					<div className="showrunner__filters--time">
						{allTime ?
							<div className="select u-hover-pointer u-flex u-flex-align-center" onClick={() => setAllTime(false)}> <span>All time</span>  <span className="caret"></span></div> :
							<div className="date-range">
								<DateRangePicker
									startDate={startDate}
									endDate={endDate}
									onDatesChange={({ startDate, endDate }) => {
										setStartDate(startDate);
										setEndDate(endDate);
									}}
									focusedInput={focusedInput}
									onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
									numberOfMonths={1}
									isOutsideRange={() => false}
									displayFormat="MM/DD/YYYY"
								/>
								<Button buttonProps={{ onClick: () => setAllTime(true) }} sm={true} className="showrunner__filters--reset">Reset</Button>
							</div>
						}
					</div>

				</div>

				<Table>
					<TableHead>
						<TableRow>
							<TH onClick={() => handleSort('date_created')} className="u-hover-pointer">
								<div className="u-flex u-flex-all-center u-full-width ">
									<div className="">
										Date
									</div>
									<div className={`caret ${sortColumn === 'date_created' ? (sortOrder === 'asc' ? 'asc' : 'desc') : 'hidden'}`}></div>
								</div>
							</TH>
							<TH>Event</TH>
							<TH>Name</TH>
							<TH>Email</TH>
							<TH onClick={() => handleSort('total')} className="u-hover-pointer">
								<div className="u-flex u-flex-all-center">
									<div>
										Order Value
									</div>
									<div className={`caret ${sortColumn === 'total' ? (sortOrder === 'asc' ? 'asc' : 'desc') : 'hidden'}`}></div>
								</div>
							</TH>
							<TH>Seats</TH>
							<TH>Tickets Sold</TH>
							<TH>Status</TH>
						</TableRow>
					</TableHead>
					<TableBody>
						{filteredOrders.map(order => (
							<TableRow key={order.id}>
								<TD>{utils.formatDateTime(order.date_created)[0]}</TD>
								<TD data-tip={order.show}>{order.show}</TD>
								<TD data-tip={order.full_name}>
									<div className="ellipsis" data-tip={order.full_name}>
										{order.full_name}
									</div>
									<ReactTooltip place="top" type="dark" effect="solid" />
								</TD>
								<TD>
									<div className="u-flex u-flex-align-center">
										<FontAwesomeIcon
											icon={faCopy}
											className="u-hover-pointer u-mgn-left-sm"
											onClick={() => handleCopy(order.email)}
										/>
										<div className="u-mgn-left-sm ellipsis" data-tip={order.email}>
											{order.email}
										</div>
										<ReactTooltip place="top" type="dark" effect="solid" />
									</div>
								</TD>
								<TD>{utils.formatFinancial(order.total)}</TD>
								<TD>{order.tickets ? order.tickets : "N/A"}</TD>
								<TD>{order.tickets && order.gratis ? order.tickets.split(",").length : order.tickets_sold}</TD>
								<TD>
									{order.refund ?
										<p>Refunded</p>
										:
										<Button className="showrunner__refund__btn" sm={true} ko={true} buttonProps={{ onClick: () => { setActiveOrder(order); toggleRefundModal(true); setRefund({ ...refund, ["refund_amount"]: order.total }) } }}>Process Refund</Button>
									}
								</TD>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>

			<Modal isOpen={refundModalOpen} toggle={() => toggleRefundModal(!refundModalOpen)}>
				<ModalHeader>
					Refund Order
					<div className="modal__close" onClick={() => toggleRefundModal(false)}>&#x2715;</div>
				</ModalHeader>
				<ModalBody>
					<div className="u-pad-md">
						<div className="u-mgn-btm-sm">
							<form>
								<div className="u-mgn-btm-sm">
									<p className="p__1">Refund Amount</p>
									<Input className={"p__1"} inputProps={{ name: "refund_amount", onChange: handleRefundChange, placeholder: activeOrder.name, value: refund.refund_amount, type: "number" }} price={true} />
								</div>
								<Button buttonProps={{ onClick: processRefund, type: "button" }}>Submit Refund</Button>
							</form>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
}