import axios from "axios";
import utils from "./utils";

let config;
function resetToken() {
  if (localStorage.getItem('access')) {
    config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access')}`
      }
    }
  } else {
    config = {}
  }
}

function getCSRFToken() {
    // Retrieve CSRF token from cookies
    const csrftoken = document.cookie.split('; ')
      .find(row => row.startsWith('csrftoken='))
      ?.split('=')[1];
    // Ensure Axios config includes CSRF token in headers
    const authHeaders = {
      headers: {
        // Other headers...
        'X-CSRFToken': csrftoken,
      },
      withCredentials: true // Necessary if your Django backend requires cookies to be sent with requests
    };

    return authHeaders
}
resetToken()
export default {
  // Auth API
  getAuth: function (unauthorized = false) {
    resetToken()
    unauthorized ? config = {} : config = config
    return axios.get("/api/accounts/auth", getCSRFToken())
  },  
  logIn: function (data) {
    // return axios.post("/token/", data)
    return axios.post("/api/accounts/login/", data, getCSRFToken());
  },
  signUp: req => axios.post("/api/accounts/signup/", req, getCSRFToken()),
  logOut: function () {
    const authHeaders = getCSRFToken();
    return axios.post("/api/accounts/auth", {}, authHeaders);
  },
  forgotPassReq: req => axios.post("/api/accounts/password-reset/", req),
  resetPass: (uidb64, token, req) => axios.post(`/api/accounts/password-reset-confirm/${uidb64}/${token}/`, req),
  getOrders: (showID, action) => axios.get(`/api/shows/orders?id=${showID}&action=${action}`, getCSRFToken()),
  confirmOrderDelivery: req => axios.post(`/api/shows/orders`, req),
  getProduction: prodID => axios.get(`/api/shows/productions?prodID=${prodID}`),
  getShows: params => axios.get(`/api/shows${params}`),
  getShow: showID => axios.get(`/api/shows/${showID}`),
  updateShow: (showID, req) => axios.put(`/api/shows/${showID}`, req, getCSRFToken()),
  updateShowPartial: (showID, req) => axios.patch(`/api/shows/${showID}`, req, getCSRFToken()),
  addShow: req => axios.post(`/api/shows/`, req, getCSRFToken()),
  getShowRunner: userID => axios.get(`/api/accounts/showrunner?userID=${userID}`, getCSRFToken()),
  getTheaters: () => axios.get(`/api/shows/theaters`),
  getCustomers: userID => axios.get(`/api/accounts/customers?userID=${userID}`, getCSRFToken()),
  getShowTickets: (showID) => axios.get(`/api/shows/tickets?showID=${showID}&`),
  getEventTix: (showID) => axios.get(`/api/shows/event?showID=${showID}`),
  objectToParams: obj => {
    let query = "?"
    let counter = 1
    const total = Object.keys(obj).length
    for (let key in obj) {
      query += key + "=" + obj[key]
      if (counter < total) {
        query += "&"
      }
      counter++
    }
    return query
  },
  postPayment: req => axios.post("/api/shows/payments", req, getCSRFToken()),
  sendReferralTake: req => axios.post("/api/accounts/referral", req, getCSRFToken()),
  printTickets: req => axios.post("/api/shows/willcall", req),
  checkTicketStatus: (tickets, showID, action) => axios.get(`/api/shows/payments?showID=${showID}&action=${action}&tickets=${tickets}`),
  sendTickets: req => axios.post(`/api/shows/tickets`, req, getCSRFToken()),
  submitGratis: req => axios.post(`/api/showrunner/gratis`, req, getCSRFToken()),
  getPalaceShowTix: showID => axios.get(`/api/shows/palace?showID=${showID}`),
  scanTicket: code => axios.get(`/api/shows/scan?code=${code}`),
  getWillCallOrders: (method, value, showID) => axios.get(`/api/shows/willcall?method=${method}&value=${value}&showID=${showID}`),
  willCallTickets: req => axios.post(`/api/shows/willcall`, req, getCSRFToken()),
  troubleShoot: orderID => axios.get(`/api/shows/troubleshoot?order=${orderID}`, getCSRFToken()),
  checkDiscount: (showID, code) => axios.get(`/api/shows/discount?showID=${showID}&code=${code}`),
  // Showrunner API
  // Showrunner Profile
  updateShowrunner: (id, req) => axios.patch(`/api/accounts/showrunner?id=${id}`, req, getCSRFToken()),
  updateProfile: (id, req) => axios.put(`/api/accounts/showrunner?id=${id}`, req, getCSRFToken()),
  // Events
  addEvent: req => axios.post(`/api/showrunner/events`, req, getCSRFToken()),
  updateEvent: req => axios.put(`/api/showrunner/events`, req, getCSRFToken()),
  // Shows
  getShowManage: (showID, showrunnerID) => axios.get(`/api/showrunner/show/${showID}?showrunnerID=${showrunnerID}`, getCSRFToken()),
  deleteShow: showID => axios.delete(`/api/showrunner/show/${showID}`, getCSRFToken()),
  // Payments
  signUpStripeConnect: () => axios.post(`/api/showrunner/payments-accounts`, {},getCSRFToken()),
  getStripeConnectDash: stripeConnectID => axios.get(`/api/showrunner/payments-accounts?stripe_connect_id=${stripeConnectID}`, getCSRFToken()),
  getConnectAccountLink: stripeConnectID => axios.get(`/api/showrunner/payments-accounts-sessions?stripe_connect_id=${stripeConnectID}`, getCSRFToken()),
  stripeConnectSession: req => axios.post(`/api/showrunner/payments-accounts-sessions`, req,getCSRFToken()),
  // Productions
  createProduction: req => axios.post(`/api/shows/productions`, req, getCSRFToken()),
  updateProduction: (prodID, req) => axios.patch(`/api/shows/productions/${prodID}`, req, getCSRFToken()),
  // Tickets
  editTickets: req => axios.put(`/api/showrunner/tickets`, req, getCSRFToken()),
  addTickets: req => axios.post(`/api/showrunner/tickets`, req, getCSRFToken()),
  // Ticket Categories
  getTicketCategories: showID => axios.get(`/api/showrunner/ticket_categories?showID=${showID}`, getCSRFToken()),
  addTicketCategory: req => axios.post(`/api/showrunner/ticket_categories/`, req, getCSRFToken()),
  editCategory: (req) => axios.put(`/api/showrunner/ticket_categories/`, req, getCSRFToken()),
  // Subcategories
  getSubcategories: categoryID => axios.get(`/api/showrunner/subcategories?categoryID=${categoryID}`, getCSRFToken()),
  addSubcategory: req => axios.post(`/api/showrunner/subcategories`, req, getCSRFToken()),
  editSubcategory: req => axios.put(`/api/showrunner/subcategories`, req, getCSRFToken()),
  // Discounts
  createDiscountCode: req => axios.post("/api/shows/discounts", req, getCSRFToken()),
  getDiscounts: showID => axios.get(`/api/shows/discounts?showID=${showID}`, getCSRFToken()),
  editDiscount: req => axios.patch(`/api/shows/discounts`, req, getCSRFToken()),
  deleteDiscount: discountID => axios.delete(`/api/shows/discounts?discountID=${discountID}`, getCSRFToken()),
  // Orders
  getRecentOrders: (showrunnerID, limit, days, startDate, endDate, showID) => {
    let url = `/api/orders?showrunner_id=${showrunnerID}`;
    if (limit) url += `&limit=${limit}`;
    if (days) url += `&days=${days}`;
    if (startDate) url += `&start_date=${startDate}`;
    if (endDate) url += `&end_date=${endDate}`;
    if (showID) url += `&show_id=${showID}`;
    return axios.get(url, getCSRFToken());
  },
  // Refunds
  refundOrder: req => axios.post(`/api/orders/refund`, req, getCSRFToken()),
  // Customers
  getCustomerEmails: (filters) => {
    const { showrunner_id, start_date, end_date, show_id, allTime } = filters;
    const params = new URLSearchParams();
    params.append("showrunner_id", showrunner_id);

    if (!allTime) {
        if (start_date) params.append("start_date", start_date);
        if (end_date) params.append("end_date", end_date);
    }

    if (show_id > 0) {
        params.append("show_id", show_id);
    }

    return axios.get(`/api/showrunner/customers?${params.toString()}`, getCSRFToken());
},
  // Charts
  getOrdersChart: (showrunnerID, dataType, timeRange ) => axios.get(`/api/orders/charts?showrunner_id=${showrunnerID}&data_type=${dataType}&time_range=${timeRange}`, getCSRFToken()), 
  // Financial
  getFinancial: userID => axios.get(`/api/accounts/financial?userID=${userID}`, getCSRFToken()),
  getFinancialData: (showrunnerID, filters) => axios.get(`/api/showrunner/financial_data?showrunner_id=${showrunnerID}&${utils.buildQueryString(filters)}`, getCSRFToken()),
  getReferralCharts: (showrunnerID, filters) => axios.get(`/api/showrunner/referral_charts?showrunner_id=${showrunnerID}&${utils.buildQueryString(filters)}`, getCSRFToken()),
  getReferrals: showrunnerID => axios.get(`/api/showrunner/referrals?showrunner_id=${showrunnerID}`, getCSRFToken()),
  // GOD MODE
  getGod: (filters) => axios.get(`/api/showrunner/god`, { params: filters, ...getCSRFToken() }),
  getClients: (showrunnerID = null) => axios.get(`/api/showrunner/clients?showrunner_id=${showrunnerID}`, getCSRFToken()),
}
