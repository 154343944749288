import React, { useEffect, useState } from "react";
import Input from "../../../components/Input";
import API from "../../../utils/API";
import utils from "../../../utils/utils";
import Button from "../../../components/buttons/Button";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageUpload from "../../../components/ImageUpload";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../utils/authContext";


export default function EventDetails(props) {
  const { showrunner } = useAuth();
  const location = useLocation();
  const initEventDetails = {
    name: "",
    date: "",
    location: "",
    venue: "",
    production: {
      poster: "",
      description: "",
      poster_portrait: ""
    }
  };
  const [eventDetails, setEventDetails] = useState(initEventDetails);
  const [prodDesc, setProdDesc] = useState("");
  const [showID, setShowID] = useState(0);

  useEffect(() => {
    reset();
  }, []);

  function reset() {
    const params = new URLSearchParams(location.search);
		const showID = params.get("showID");
    setShowID(showID);
    API.getShow(showID)
      .then(res => {
        setEventDetails(res.data);
        setProdDesc(res.data.production.description);
      });
  }

  function handleShowChange(e) {
    const { name, value, type, files } = e.target;
    if (!e.target) return;
    if (type === "file") {
      setEventDetails({ ...eventDetails, [name]: files[0] });
    } else {
      setEventDetails({ ...eventDetails, [name]: value });
    }
  }

  function handleProdChange(e) {
    const { name, value, type, files } = e.target;
    if (!e.target) return;
    if (type === "file") {
      const production = eventDetails.production;
      production[name] = files[0];
      setEventDetails({ ...eventDetails, production });
    } else {
      setEventDetails({ ...eventDetails, production: { ...eventDetails.production, [name]: value } });
    }
  }

  function updateEvent() {
    const req = new FormData();

    // Add production data
    Object.keys(eventDetails.production).forEach(key => {
        if (eventDetails.production[key]) {
            req.append(`production.${key}`, eventDetails.production[key]);
        }
    });

    // Add show data
    Object.keys(eventDetails).forEach(key => {
        if (key !== 'production' && eventDetails[key]) {
            req.append(`show.${key}`, eventDetails[key]);
        }
    });

    req.append('production.description', prodDesc);

    // Add showID
    req.append('showID', showID);

    API.updateEvent(req)
        .then(res => {
            utils.sendAlert("Event updated", "success");
            props.reset(showID, showrunner.id);
        })
        .catch(err => {
            console.log("err", err);
            utils.sendAlert("Error updating event", "error");
        });
  }

  return (
    <>
      <div className="event-manager__details">
        <div className="event-manager__details--left">
          <h3 className="heading__3 u-mgn-btm-md">Edit Event Details</h3>
          <div className="event-manager__details--group">
            <div className="event-manager__details--subgroup">
              <Input label="Date and Time" name="date" onChange={handleShowChange} inputProps={{ placeholder: "DateTime", type: "datetime-local", value: utils.formatDateTimeLocal(eventDetails.date), onChange: handleShowChange }} />
            </div>
          </div>
          <div className="event-manager__details--group">
            <div className="event-manager__details--subgroup">
              <label className="input__label" >Description</label>
              <ReactQuill theme="snow" value={prodDesc} name="description" onChange={setProdDesc} />
            </div>
          </div>
          <div className="event-manager__details--group">
            <div className="event-manager__details--subgroup">
              <Input inputProps={{ placeholder: "Venue", value: eventDetails.venue, onChange: handleShowChange, name: "venue" }} label="Venue"/>
            </div>
          </div>
          <div className="event-manager__details--group">
            <div className="event-manager__details--subgroup">
              <Input inputProps={{ placeholder: "Address", value: eventDetails.location, onChange: handleShowChange, name: "location" }} label="Address"/>
            </div>
          </div>
        </div>
        <div className="event-manager__details--right">

          <div className="event-manager__details--group">
            <div className="event-manager__details--subgroup">
              <p className="p__1" >Poster</p>
              {eventDetails.production.poster_portrait ? <img src={eventDetails.production.poster_portrait} alt="event poster" width="100px" className=" u-mgn-btm-sm"/> : <p className="p__2">No Poster</p>}
              <ImageUpload inputProps={{ placeholder: "Poster", onChange: handleProdChange, name: "poster_portrait" }} name="poster_portrait" small={eventDetails.production.poster_portrait ? true : false}/>
            </div>
          </div>
        </div>
      </div>
      <div className="event-manager__details--btn-container">

        <Button className={"event-manager__details--btn"} buttonProps={{ onClick: updateEvent }}action={true}>Update Event</Button>
      </div>
    </>
  );
}