import React, { useState, useEffect } from "react";
import { ReactComponent as WaveIcon } from '../../assets/img/SVG/blue-wave.svg';
import { ReactComponent as StripeIcon } from '../../assets/img/SVG/stripe-black.svg';
import { ReactComponent as Logo } from '../../assets/img/slicktix_logo_black.svg';
import Button from "../../components/buttons/Button";
import useStripeConnect from "../../components/stripe/useStripeConnect";
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import API from "../../utils/API";


export default function StripeSignUp(props) {
  const [connectedAccountId, setConnectedAccountId] = useState();
  const [accountCreatePending, setAccountCreatePending] = useState(false);
  const [onboardingExited, setOnboardingExited] = useState(false);
  const [stripeAcctLink, setStripeAcctLink] = useState(false);
  const [error, setError] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  // Instantiate the Stripe Connect instance when an account ID is set
  const stripeConnectInstance = useStripeConnect(connectedAccountId);

  useEffect(() => {
    if (props.showrunner && props.showrunner.stripe_connect_id) {
      setFirstTime(false);
      API.getStripeConnectDash(props.showrunner.stripe_connect_id)
        .then(res => {
          const { link, error } = res.data
          if (link) {
            setStripeAcctLink(link)
            // display link
          } else if (error) {
            // display error
            API.getConnectAccountLink(props.showrunner.stripe_connect_id)
              .then(res2 => {
                console.log("Stripe connect link res2", res2)
              })
          }
        })
        .catch(e => {
          console.error("Error getting stripe connect dash", e)
        })
    } else {
      console.log("No stripe connect id")
    }
  }, [props.showrunner])


  function handleCreateAccount() {
    setAccountCreatePending(true);
    setError(false);
    API.signUpStripeConnect()
      // .then(response => response.json())
      .then(res => {
        setAccountCreatePending(false);
        const { error, account } = res.data;
        if (account) {
          setConnectedAccountId(account);
        }
        if (error) {
          setError(true);
        }
        API.updateShowrunner(props.userID, { stripe_connect_id: account })
          .then(res2 => {

          })
        API.getConnectAccountLink(account)
          .then(res => {
            setStripeAcctLink(res.data.link)
          })
      }).catch(e => {
        setError(true);
        setAccountCreatePending(false);
        console.error('Error creating Stripe account:', e);
      });
  };

  return (
    <>
      <div className="financial__stripe">
        <div className="financial__stripe__logos">

          <StripeIcon className="financial__stripe__logo" />
          <div className="financial__stripe__separator"></div>
          <div className="financial__stripe__logo--pad">

            <Logo className="financial__stripe__logo" />
          </div>
        </div>
        {stripeAcctLink ?
          <>
            <a className="u-mgn-top-md" href={stripeAcctLink} target="_blank" rel="noreferrer">
              <Button>{firstTime ? "Finish Stripe sign up" : "Go to Stripe"}</Button>
            </a>
          </>
          :
          <>
            <h5 className="heading__5 u-mgn-btm-sm">Let's start selling tickets!</h5>
            <p className="p__2 u-mgn-btm-sm">The first thing you'll need to do is sign up with Stripe. </p>
            <p className="p__2 u-mgn-btm-sm">Stripe is a secure payment service that will send <i>all</i> of your ticket revenue directly to the bank account(s) of your choice.</p>
              {!connectedAccountId && (
                <Button className="u-mgn-top-md" disabled={accountCreatePending} buttonProps={{ onClick: handleCreateAccount }}>{accountCreatePending ? "Creating Account..." : "Get started with Stripe"}</Button>
              )}
              {stripeConnectInstance && (
                <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
                  <ConnectAccountOnboarding
                    onExit={() => setOnboardingExited(true)}
                  />
                </ConnectComponentsProvider>
              )}
              {/* {error && <p className="error">Something went wrong!</p>} */}
              {(connectedAccountId || accountCreatePending || onboardingExited) && (
                <div className="dev-callout">
                  {connectedAccountId && <p>Your connected account ID is: <code className="bold">{connectedAccountId}</code></p>}
                  {accountCreatePending && <p>Creating a connected account...</p>}
                  {onboardingExited && <p>The Account Onboarding component has exited</p>}
                </div>
              )}
            </>
        }
            <WaveIcon className="financial__wave" />
          </div>
      </>
      )
}