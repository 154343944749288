import React, { useEffect, useState, useRef } from "react";
import { useParams } from 'react-router-dom';
// import renderRows from "./PalaceOfFineArts/Rows";
// import renderCHRows from "./ChorusHall/Rows";
import API from "../utils/API";
import Navbar from "../components/Navbar";
import Button from "../components/buttons/Button";
// import FloorPlan from "./FloorPlan";
import Input from "../components/Input";
import CheckoutForm from "../components/CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import ClipLoader from "react-spinners/ClipLoader";
import Footer from "../components/Footer";
import utils from "../utils/utils";
import Seat from "../components/Seat"
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import TicketTypeSelector from "../components/TicketTypeSelector";
import Discount from "../components/Discount";
import { CalendarIcon, LoacationIcon } from "../assets/img/event/svg";
import FloorPlan from "./newfloorplan/Floorplan";

let promise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
let showID
export default function Event() {
  showID = useParams().showID

  const initState = {
    seatData: {},
    legendItems: { 1: {} },
    legend: {
      Available: "#750D37",
      "ADA Seating": "#558C8C",
      Selected: "#310014",
      Taken: "#535558"
    },
    readyForCheckout: false,
    showID: 0
  }

  const initSeatState = {
    selectedSeats: {},
    seatIndex: {}
  }

  const initCheckoutInfo = {
    email: "",
    confirmEmail: "",
    fullName: "",
    mailingAddress: "",
    cardNumber: "",
    zipCode: ""
  }

  const initPreDiscount = {
    total: 0,
    bof: 0,
    ccFee: 0
  }

  const initFloorPlanState = {
    floorPlan: []
  }

  const [state, setState] = useState(initState);
  const [auth, setAuth] = useState({});
  const [floorPlanState, setFloorPlanState] = useState(initFloorPlanState);
  const [loading, setLoading] = useState(true);
  const [prod, setProd] = useState({});
  const [seatState, setSeatState] = useState(initSeatState);
  const [total, setTotal] = useState(0);
  const [prevTotal, setPrevTotal] = useState(0);
  const [deliveryMethod, setDeliveryMethod] = useState("email");
  const [checkoutInfo, setCheckoutInfo] = useState(initCheckoutInfo);
  const [modalState, setModalState] = useState({ modalOpen: false });
  const [showState, setShow] = useState({});
  const [ticketCategories, setTicketCategories] = useState({});
  const [tixAddable, setTixAddable] = useState(0);
  const [bof, setBof] = useState(0);
  const [ccFee, setCcFee] = useState(0);
  const [tixAvailable, setTixAvailable] = useState(0);
  const [discountApplied, setDiscountApplied] = useState(false);
  const [checkoutReady, setCheckoutReady] = useState(false);
  const [payReady, setPayReady] = useState(false);
  const [preDiscount, setPreDiscount] = useState(initPreDiscount);
  const [allTix, setAllTix] = useState([]);
  const [theater, setTheater] = useState(false);

  function handleChange(e) {
    setCheckoutInfo({ ...checkoutInfo, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    reset(showID)
  }, [showID])

  useEffect(() => {
    const newBof = parseFloat((total * prod.bof).toFixed(2))
    setBof(newBof);
    setCcFee(parseFloat(utils.calculateStripeFee(total + newBof).toFixed(2)))
  }, [total])


  useEffect(() => {
    const { published } = showState
    if (!published) {
      if (auth.anonymous) {
        utils.sendAlert("This show is not published", "error")
        return window.location.href = "/"
      } else if (auth.id) {
        utils.sendAlert("This show is not live. Only you can view this page until this event is live.", "error")
        API.getShowRunner(auth.id)
          .then(res => {
            const showrunnerID = res.data.id
            if (!showState.production.users.includes(showrunnerID)) {
              return window.location.href = "/"
            }
          })
      }
    }
  }, [auth])

  function calculateFeeTotal() {
    let feeTotal = 0
    for (let key in ticketCategories) {
      const category = ticketCategories[key]
      if (Object.keys(category.subcategories).length > 0) {
        for (let subKey in category.subcategories) {
          const subcategory = category.subcategories[subKey]
          const price = subcategory.price
          const numSelected = subcategory.numSelected
          const fee = utils.calculateTicketFees(prod.bof, price * numSelected)
          feeTotal += fee
        }
      } else {
        const price = ticketCategories[key].price
        const numSelected = ticketCategories[key].numSelected
        const fee = utils.calculateTicketFees(prod.bof, price * numSelected)
        feeTotal += fee
      }
    }
    return Math.round(feeTotal * 100) / 100;
  }

  function checkout() {
    const { selectedSeats } = seatState
    setState({ ...state, readyForCheckout: true })
  }

  function reset(id) {
    if (!id || !showID) {
      console.log("No ID")
      return
    }
    setLoading(true)

    API.getEventTix(id)
      .then(res => {
        setProd(res.data.prod)
        setState({ ...state, seatData: res.data.tickets, id })
        let floorPlan
        if (res.data.show.theater) {
          // if (res.data.theater.name === "palace") {
          //   floorPlan = renderRows(res.data.tickets, seatClick)
          // } else if (res.data.theater.name === "Chorus Hall") {
          //   floorPlan = renderCHRows(res.data.tickets, seatClick)
          // }
          setAllTix(res.data.tickets)
          setTheater(res.data.theater)
        }
        setFloorPlanState({ ...floorPlanState, floorPlan })
        setLoading(false)
      })
    const params = API.objectToParams({ id })
    API.getShow(showID)
      .then(res => {
        console.log("Show res", res.data)
        setShow(res.data)
        setTicketCategories(res.data.ticket_categories)
        let tixSum = 0
        for (let key in res.data.ticket_categories) {
          tixSum += res.data.ticket_categories[key].tickets_available
        }
        setTixAvailable(tixSum)
      })
  }

  function resetFloorPlan() {
    let floorPlan
    // if (theater.name === "palace") {
    //   floorPlan = renderRows(allTix, seatClick, seatState.selectedSeats)
    // } else if (theater.name === "Chorus Hall") {
    //   floorPlan = renderCHRows(allTix, seatClick, seatState.selectedSeats)
    // }
    setFloorPlanState({ ...floorPlanState, floorPlan })
  }

  function handleTixAddable(newTixAddable, type, numSelected, obj) {
    setTixAddable(newTixAddable)
    let subcategory
    if (!obj.subcategories) {
      subcategory = ticketCategories[obj.category_name].subcategories[type]
      subcategory.numSelected = numSelected
      ticketCategories[obj.category_name].numSelected = numSelected
    } else {
      ticketCategories[type].numSelected = numSelected
    }

    let newTotal
    if (showState.theater) {
      if (newTixAddable > tixAddable) {
        newTotal = total - ticketCategories[type].price
      } else if (newTixAddable < tixAddable) {
        newTotal = total + ticketCategories[type].price
      }
    } else {
      if (subcategory) {
        if (newTixAddable > ticketCategories[obj.category_name].tickets_available) {
          newTotal = total - subcategory.price;
        } else if (newTixAddable < ticketCategories[obj.category_name].tickets_available) {
          newTotal = total + subcategory.price;
        }
      } else {
        if (newTixAddable > ticketCategories[type].tickets_available) {
          newTotal = total - ticketCategories[type].price;
        } else if (newTixAddable < ticketCategories[type].tickets_available) {
          newTotal = total + ticketCategories[type].price;
        }
      }
    }

    const copyTicketCategories = ticketCategories
    if (subcategory) {
      copyTicketCategories[obj.category_name].numSelected = numSelected
    } else {
      copyTicketCategories[type].numSelected = numSelected
    }
    setTicketCategories(copyTicketCategories)
    setTotal(newTotal)
    const newBof = calculateFeeTotal()
    const newCcFee = parseFloat(utils.calculateStripeFee(newTotal + calculateFeeTotal()).toFixed(2))
    setBof(newBof)
    setCcFee(newCcFee)
    setPreDiscount({ total: newTotal, bof: newBof, ccFee: newCcFee })

  }

  function applyDiscount(valid, discount) {

    if (valid && discount) {

      const { discount_type, amount } = discount
      if (discount_type === "percentage") {
        const newTotal = total - (total * (amount / 100))
        setTotal(newTotal)
        setDiscountApplied(true)
      } else if (discount_type === "cash_amount") {
        const newTotal = total - amount
        setTotal(newTotal)
        setDiscountApplied(true)
      }
    } else {
      utils.sendAlert("Invalid Discount Code", "error")
      return
    }
  }

  useEffect(() => {

  }, [seatState])

  function seatClick(data, cb) {
    console.log("data", data)
    const { selectedSeats, seatIndex } = seatState
    const { seat } = data
    if (selectedSeats[seat]) {
      delete selectedSeats[seat]
    } else {
      selectedSeats[seat] = data
    }
    seatIndex[seat] = cb

    setSeatState({ ...seatState, selectedSeats, seatIndex })
    const updatedCategories = { ...ticketCategories }
    let selectedCounter = 0
    for (let key in ticketCategories) {
      const category = ticketCategories[key]
      selectedCounter += category.numSelected
      category.numSelected = 0
      updatedCategories[key] = category
    }
    setTixAddable(Object.keys(selectedSeats).length)
    setTotal(0)
    setBof(0)
    setCcFee(0)
    setDiscountApplied(false)
  }

  if (loading) {
    return (
      <div className="u-full-screen u-flex-all-center">
        <ClipLoader color="#0029FF" loading={loading} css={""} size={150} speedMultiplier={.3} />
      </div>
    )
  } else {

    return (
      <div className="theater">
        <div className="theater__nav">
          <Navbar />
        </div>
        <div className="palace__wrapper">
          <h1 className="heading__1"></h1>
          {prod ?
            // <div className="event__hero" >
            //   <div className="event__hero--bg" style={{ background: `url(${prod.poster_portrait})` }}></div>
            //   <div className="event__hero--container">
            //   <div className="event__poster--container">
            //       {/* <div className="event__poster" style={{ background: `url(${prod.poster_portrait})` }}></div> */}
            //       <img className="event__poster" src={prod.poster_portrait} alt="" />

            //     </div>
            //     <div className="event__hero--left">
            //       {/* <p className="p__1">Host: {showState.showrunner && showState.showrunner.company}</p> */}
            //       <h1 className="heading__1 u-mgn-btm-sm u-align-center event__hero-heading">{prod.name}</h1>
            //       {showState.date ?
            //         <>
            //           <h5 className="heading__5 u-mgn-btm-sm">{showState.venue}</h5>
            //           <p className="p__1 u-mgn-btm-sm">
            //             <CalendarIcon className="event__hero--icon" /> {utils.formatDateTime(showState.date)[0]} at {utils.formatDateTime(showState.date)[1]}
            //           </p>
            //           <p className="p__1 u-mgn-btm-sm"><LoacationIcon className="event__hero--icon" /> {showState.location}</p>
            //           <a href="#buytix" className="event__buy-tix">
            //             <Button className="event__btn" buttonProps={{}}>Buy Tickets</Button>
            //           </a>
            //         </>
            //         : null}

            //     </div>

            //   </div>
            // </div>
            <div className="event__hero">
              <div className="event__hero__bg" style={{ background: `url(${prod.poster_portrait})` }}></div>
              {/* <div className="event__hero__poster" style={{ backgroundImage: `url(${prod.poster_portrait})` }}></div> */}
              <img src={prod.poster_portrait} alt={prod.name} className="event__hero__poster" />

              <div className="event__hero__content">
                <h3>{prod.name}</h3>
                <p>
                  <b>
                    {showState.venue}
                  </b>
                </p>
                <p>
                  <LoacationIcon className="" />
                  {showState.location}
                </p>
                <p>
                  <CalendarIcon className="" />
                  {utils.formatDateTime(showState.date)[0]} at {utils.formatDateTime(showState.date)[1]}
                </p>
                <a href="#buytix" className="event__buy-tix">
                  <Button className="event__btn" buttonProps={{}}>Buy Tickets</Button>
                </a>
              </div>
              {/* <CarouselCaption
            captionText={show.caption || show.location}
            captionHeader={show.title}
            /> */}
            </div>
            : null}
          <div className="event__body">
            <div className="event__body--left">
              {/* <h5 className="heading__5">Description</h5> */}
              <h5 className="heading__5">Description</h5>
              <div
                className="p__2 u-align-center theater__hero-text"
                dangerouslySetInnerHTML={{ __html: prod.description }}
              />

              <div className="event__host">
                {showState.showrunner &&
                  <>
                    {showState.showrunner.company &&
                      <h5>Produced by: {showState.showrunner.company}</h5>
                    }
                    <p>Contact: {showState.showrunner.email}</p>
                  </>
                }
              </div>
            </div>

            <div className="event__select event__body--right" id="buytix">
              <div className="event__select__header">
                <p className="p__1  u-font-semibold u-mgn-btm-sm">{showState.production && showState.production.name}</p>
                <p className="p__2">{utils.formatDateTime(showState.date)[0]} at {utils.formatDateTime(showState.date)[1]}</p>
              </div>

              {showState.theater && !checkoutReady && !payReady ?
                <div className="u-mgn-top-md">
                  <Button buttonProps={{ onClick: () => setModalState({ modalOpen: true }) }}>Select Seats</Button>
                </div>
                :
                <>
                  {!checkoutReady && !payReady ?
                    <div className="tts__container event__select__tickets">
                      <p className="p__1 u-mgn-vert-sm">Tickets available: {tixAvailable}</p>
                      {
                        Object.keys(ticketCategories).map((categoryKey, i) => {
                          const category = ticketCategories[categoryKey];
                          const hasSubCategories = category.subcategories && Object.keys(category.subcategories).length > 0;
                          const subcategories = Object.keys(category.subcategories)

                          // Check if the category has subcategories
                          if (hasSubCategories) {
                            // Map over subcategories if they exist
                            return (
                              <div key={category.id}>
                                <div className="tts__category">{category.name} ({category.tickets_available} tickets available)</div>
                                <div className="u-mgn-left-md">
                                  {subcategories.map((subCategory, index) => (
                                    <TicketTypeSelector
                                      key={`${categoryKey}-sub-${index}`}
                                      name={category.subcategories[subCategory].name}
                                      tixAddable={category.tickets_available}
                                      price={category.subcategories[subCategory].price}
                                      obj={category.subcategories[subCategory]}
                                      setTixAddable={handleTixAddable}
                                      numSelected={category.subcategories[subCategory].numSelected}
                                      freeSeating={true}
                                      subCategory={true}
                                    />
                                  ))}
                                </div>
                              </div>
                            );
                          } else {
                            // Use the parent category if there are no subcategories
                            return (
                              <TicketTypeSelector
                                key={categoryKey}
                                name={category.name}
                                tixAddable={category.tickets_available}
                                price={category.price}
                                subCategories={category.subcategories}  // Will be undefined or an empty array
                                obj={category}
                                freeSeating={true}
                                setTixAddable={handleTixAddable}
                                numSelected={category.numSelected}
                              />
                            );
                          }
                        })
                      }
                    </div>
                    : <> </>}
                </>
              }

              <div className="event__select">
                {!checkoutReady && !payReady ?
                  <div>
                    {Object.keys(seatState.selectedSeats).length > 0 ?
                      <div className="tts__container">
                        {
                          Object.keys(ticketCategories).map((category, i) => (
                            <TicketTypeSelector name={ticketCategories[category].name} tixAddable={tixAddable} price={ticketCategories[category].price} setTixAddable={handleTixAddable} numSelected={ticketCategories[category].numSelected} obj={ticketCategories[category]} freeSeating={false} />
                          ))
                        }
                        <div className="tts__totals u-mgn-top-md">
                          <div>Remaining tickets:</div>
                          <div>{tixAddable}</div>
                        </div>
                      </div>
                      : null}
                  </div>
                  : null}

                {checkoutReady &&
                  <div className="u-flex-all-center">
                    <Button className="u-mgn-btm-sm" buttonProps={{ onClick: () => { setCheckoutReady(false); setPayReady(false); } }}>Go Back</Button>
                  </div>
                }

                {payReady &&
                  <div className="u-flex-all-center">
                    <Button className="u-mgn-btm-sm" buttonProps={{ onClick: () => { setPayReady(false); setCheckoutReady(true); } }}>Go Back</Button>
                  </div>
                }

                <div className="event__totals">
                  {discountApplied ?
                    <div className="u-flex u-flex-justify-between u-mgn-top-sm">
                      <div className="p__2">Pre-Discount Ticket Total:</div>
                      <div className="p__2 u-strike">${preDiscount.total}</div>
                    </div>
                    : null}
                  <div className="u-flex u-flex-justify-between u-mgn-btm-sm">
                    <div className="p__1">Ticket Total:</div>
                    <div className="p__1">${total}</div>
                  </div>
                  {discountApplied ?
                    <div className="u-flex u-flex-justify-between u-mgn-top-sm">
                      <div className="p__2">Pre-Discount Fee Total:</div>
                      <div className="p__2 u-strike">${(preDiscount.ccFee + preDiscount.bof).toFixed(2)}</div>
                    </div>
                    : null}
                  <div className="u-flex u-flex-justify-between">
                    <div className="p__2">Fee:</div>
                    <div className="p__2">$ {(ccFee + bof).toFixed(2) && total !== 0 ? (ccFee + bof).toFixed(2) : "0.00"}</div>
                  </div>
                  {discountApplied ?
                    <div className="u-flex u-flex-justify-between u-mgn-top-sm">
                      <div className="p__2">Pre-Discount Total:</div>
                      <div className="p__2 u-strike">{total !== 0 ? utils.formatFinancial(preDiscount.bof + preDiscount.ccFee + preDiscount.total) : "0.00"}</div>
                    </div>
                    : null}
                  <div className="u-flex u-flex-justify-between u-mgn-top-sm">
                    <div className="p__1 u-font-semibold">Net Total:</div>
                    <div className="p__1 u-font-semibold"> {total !== 0 ? utils.formatFinancial(total + calculateFeeTotal() + utils.calculateStripeFee(total + calculateFeeTotal())) : "0.00"}</div>
                  </div>
                </div>

                {showState.has_discounts && checkoutReady ?
                  <div className="u-mgn-vert-sm">
                    <p className="p__1">Discount</p>
                    <Discount cb={applyDiscount} showID={showID} total={total} applied={discountApplied} tixAddable={tixAddable} />
                  </div> : null
                }

                {!checkoutReady && !payReady ?
                  <div className="u-flex-all-center">

                    <Button
                      // className={`${total < 1 ? "theater__cart__btn button--disabled" : "theater__cart__btn button"}`} disabled={total < 1 } 
                      className={`${showState.theater ? (Object.keys(seatState.selectedSeats).length > 0 && tixAddable < 1 ? "theater__cart__btn button" : "theater__cart__btn button--disabled") : (total < 1 ? "theater__cart__btn button--disabled" : "theater__cart__btn button")}`}
                      disabled={showState.theater ? !(Object.keys(seatState.selectedSeats).length > 0 && tixAddable < 1) : total < 1}
                      buttonProps={{
                        onClick: () => {
                          if (total > 0 || Object.keys(seatState.selectedSeats).length > 0) {
                            setCheckoutReady(true);
                          } else {
                            setCheckoutReady(false);
                          }
                        }
                      }}
                    >Checkout </Button>
                  </div>
                  : null}



                {checkoutReady ?
                  <div className="u-mgn-top-md">
                    <p className="p__2 u-mgn-btm-md">Let us know who to send the tickets to:</p>

                    <div className="u-mgn-btm-sm">
                      <p className="p__1">Full name</p>
                      <Input className={"p__1"} inputProps={{ required: true, name: "fullName", onChange: handleChange }} />
                    </div>
                    <div className="u-mgn-btm-sm">
                      <p className="p__1">Email</p>
                      <Input className={"p__1"} inputProps={{ required: true, name: "email", onChange: handleChange }} />
                    </div>
                    <div className="u-mgn-btm-sm">
                      <p className="p__1">Confirm Email</p>
                      <Input className={"p__1"} inputProps={{ required: true, name: "confirmEmail", onChange: handleChange }} />
                    </div>
                    <div className="u-flex-all-center">

                      {!checkoutInfo.email || checkoutInfo.confirmEmail !== checkoutInfo.email ?
                        <Button className="u-mgn-btm-sm theater__cart__btn" disabled={true}>Payment</Button>
                        :
                        <Button className="u-mgn-btm-sm theater__cart__btn" buttonProps={{ onClick: () => { setCheckoutReady(false); setPayReady(true); } }}>Payment</Button>
                      }
                    </div>
                  </div>
                  : null}
                {payReady ?
                  <>
                    <p className="p__1">Credit/Debit Card</p>
                    <Elements stripe={promise}>
                      <CheckoutForm ticketsTotal={total}
                        customerTotal={total + bof + ccFee}
                        ccFee={ccFee}
                        stFeeTotal={bof}
                        total={total + bof}
                        emailTotal={total + bof + ccFee}
                        seats={seatState.selectedSeats}
                        showID={showID}
                        email={state.email}
                        checkoutInfo={checkoutInfo}
                        confirmEmail={state.confirmEmail}
                        deliveryMethod={deliveryMethod}
                        checkoutFail={reset}
                        ticketCategories={ticketCategories}
                        freeSeating={!showState.theater}
                        closeModal={() => setModalState(false)}
                        connectAcct={prod.stripe_connect_id}
                      />
                    </Elements>
                  </>
                  : null}
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <Modal className="event__modal" isOpen={modalState.modalOpen} toggle={() => setModalState({ modalOpen: !modalState.modalOpen })} onClosed={resetFloorPlan}>
          <ModalHeader style={{ backgroundColor: "white" }}>
            <div className="modal__close modal__close--black" onClick={() => setModalState({ modalOpen: false })}>&#x2715;</div>
          </ModalHeader>
          <ModalBody>
            <div className="event__floorplan">
              <div className="seat__stage" style={{ width: "60%" }}>Stage</div>
              <FloorPlan eventId={showID} seatClick={seatClick} selectedSeats={seatState.selectedSeats
              } theater={theater} />
            </div>
            <div className="theater__info">
              <div className="u-flex u-flex-align-end theater___overview">
                <div className="u-mgn-neg-btm u-mgn-right-sm">
                  <Seat name="" client="palace" unclickable={true} />
                </div>
                <span className="p__1 u-mgn-right-md theater__overview__text">SELECTED SEATS</span>
                <div className="theater__cart">
                  {Object.keys(seatState.selectedSeats).map((ticket, i) => (
                    i < Object.keys(seatState.selectedSeats).length - 1 ?
                      <span className="p__2 u-mgn-right-sm">
                        {seatState.selectedSeats[ticket].seat},
                      </span>
                      :
                      <span className="p__2">
                        {seatState.selectedSeats[ticket].seat}
                      </span>
                  ))}
                </div>
              </div>
              <Button className="event__continue" buttonProps={{ onClick: () => setModalState({ modalOpen: false }) }}>Continue</Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}
