import React, { useEffect, useState } from "react";
import hamburger from "../assets/img/hamburger.jpeg";
import { Link } from "react-router-dom";
import { useAuth } from '../utils/authContext';
import API from "../utils/API";
import utils from "../utils/utils";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

import Input from "./Input";
import Button from "./buttons/Button";
import Footer from "../components/Footer";
import { ReactComponent as Logo } from '../assets/img/SVG/logo-blue.svg';
import { ClipLoader } from "react-spinners";


export default function Navbar({ className, logo = true, toggleCursor = false }) {
	const initState = {
		isAuthenticated: false
	}

	const { auth, isAuthenticated } = useAuth();

	const initLoginState = {
		username: "",
		password: ""
	}
	const [modalState, setModalState] = useState({ modalOpen: false })
	const [signUpModalOpen, toggleSignUpModal] = useState(false)
	const [forgotPassModalOpen, toggleForgotPassModal] = useState(false)
	const [navModal, setNavModal] = useState(false);

	const toggleNav = () => setNavModal(!navModal);
	const [state, setState] = useState(initState)
	const [loginState, setLoginState] = useState(initLoginState)
	const [signUpData, setSignUpData] = useState({
		username: "",
		email: "",
		first_name: "",
		last_name: "",
		password: "",
		referrer: ""
	})
	const [resetEmail, setResetEmail] = useState("");
	const [howDidYouHear, setHowDidYouHear] = useState("");
	const [signupPending, setSignupPending] = useState(false);


	function handleChange(e) {
		setLoginState({ ...loginState, [e.target.name]: e.target.value })
	}

	function handleSignUpChange(e) {
		setSignUpData({ ...signUpData, [e.target.name]: e.target.value })
	}

	function handleResetEmailChange(e) {
		setResetEmail(e.target.value)
	}

	function handleHDYH(e) {
		setHowDidYouHear(e.target.value)
	}	

	function login(e) {
		e.preventDefault()
		const data = {
			username: loginState.username,
			password: loginState.password
		}
		API.logIn(data)
			.then(res => {
				localStorage.setItem("access", res.data.access)
				localStorage.setItem("refresh", res.data.refresh)
				setState({ ...state, isAuthenticated: true })
				API.getAuth()
					.then(res => {
						window.location.href = "/showrunner/home"
					})
			})
			.catch(err => {
				utils.sendAlert("Invalid login credentials. Please try again", "error")
			})
	}

	function signup(e) {
		e.preventDefault();

		if (!signUpData.username || !signUpData.email || !signUpData.first_name || !signUpData.last_name || !signUpData.password || !howDidYouHear) {
			utils.sendAlert("Please fill out all fields", "error");
			return
		}
		const req = { user: signUpData, hdyh: howDidYouHear };
		setSignupPending(true);
		API.signUp(req)
			.then((res, err) => {
				// After successful sign-up, log the user in
				console.log(res);
				console.log(err);
				const loginData = {
					username: signUpData.username,
					password: signUpData.password
				};
				if (howDidYouHear === "Google") {
					window.gtag('event', 'ads_conversion_Sign_up_1', {
						// <event_parameters>
					});
				} 

				API.logIn(loginData)
					.then(loginRes => {
						localStorage.setItem("access", loginRes.data.access);
						localStorage.setItem("refresh", loginRes.data.refresh);
						setState({ ...state, isAuthenticated: true });
						API.getAuth()
							.then(authRes => {
								utils.sendAlert("Account created successfully. Welcome to your dashboard.", "success");
								window.location.href = "/showrunner/home";
							})
							.catch(authErr => {
								utils.sendAlert("Authentication failed. Please try logging in manually.", "error");
							});
					})
					.catch(loginErr => {
						utils.sendAlert("Login after sign-up failed. Please try logging in manually.", "error");
					});
			})
			.catch(signUpErr => {
				console.log(signUpErr.response);
				if (signUpErr.response.data && signUpErr.response.data.username) {
					utils.sendAlert("Username already taken. Please choose another.", "error");
					setSignupPending(false);
				} else {
					utils.sendAlert("Sign-up failed. Please try again.", "error");
					setSignupPending(false);
				}
			});
	}

	function forgotPass(e) {
		e.preventDefault()
		API.forgotPassReq({ email: resetEmail })
			.then(res => {
				utils.sendAlert("A password reset email has been sent to your email address.", "success")
			})
	}

	function logout() {
		localStorage.removeItem("access")
		localStorage.removeItem("refresh")
		API.logOut()
			.then(res => {
				window.location.href = "/"
			})
	}



	return (
		<>
			<div className={`navbar ${className ? className : ""}`}>
				<div className="u-flex-justify-center-self">
					{logo &&
						<Link to="/">
							<div className="u-flex u-flex-align-center">
								<Logo className="navbar__logo" />
							</div>
						</Link>
					}
				</div>

				<div className="navbar__section navbar__section--right">
					{isAuthenticated ?
						null
						:
						<>
							<div className="navbar__auth">

								<p className="login__link u-pad-sm" onClick={() => setModalState({ ...modalState, modalOpen: true })}>
									Sign In
								</p>
								<Button buttonProps={{ onClick: () => toggleSignUpModal(true) }}>Launch Event</Button>
							</div>
						</>
					}

					<div className="navbar__menu" onClick={() => toggleNav(true)}>
						<img src={hamburger} />
					</div>

				</div>
			</div>

			<Modal isOpen={modalState.modalOpen} toggle={() => setModalState({ modalOpen: !modalState.modalOpen })} onClosed={() => { toggleCursor && toggleCursor(true) }} onOpened={() => { toggleCursor && toggleCursor(false) }}>
				<ModalHeader>
					Sign In
					<div className="modal__close" onClick={() => setModalState({ modalOpen: false })}>&#x2715;</div>
				</ModalHeader>
				<ModalBody>
					<div className="u-pad-md">

						<form onSubmit={login}>
							<div className="u-mgn-btm-sm">
								<Input className="p__1" inputProps={{ type: "text", name: "username", placeholder: "Username", onChange: handleChange }} label="Username" />
							</div>
							<div className="u-mgn-btm-sm">
								<Input className="p__1" inputProps={{ type: "password", name: "password", placeholder: "Password", onChange: handleChange }} label="Password" />
							</div>
							<Button buttonProps={{ type: "submit" }}>Log In</Button>
						</form>
						<p className="p__2 u-secondary-hov u-mgn-top-md" onClick={() => { setModalState({ modalOpen: false }); toggleForgotPassModal(true) }}>Forgot Password?</p>
					</div>
				</ModalBody>
			</Modal>

			<Modal isOpen={signUpModalOpen} toggle={() => toggleSignUpModal(!signUpModalOpen)} onClosed={() => { toggleCursor && toggleCursor(true) }} onOpened={() => { toggleCursor && toggleCursor(false) }}>
				<ModalHeader>
					Sign Up
					<div className="modal__close" onClick={() => toggleSignUpModal(false)}>&#x2715;</div>
				</ModalHeader>
				<ModalBody>
					<div className="u-pad-md">

						<form onSubmit={signup}>
							<div className="u-mgn-btm-sm">
								<Input className="p__1" inputProps={{ type: "text", name: "username", placeholder: "Username", onChange: handleSignUpChange }} label="Username" />
							</div>
							<div className="u-mgn-btm-sm">
								<Input className="p__1" inputProps={{ type: "email", name: "email", placeholder: "Email", onChange: handleSignUpChange }} label="Email" />
							</div>
							<div className="u-mgn-btm-sm">
								<Input className="p__1" inputProps={{ type: "text", name: "first_name", placeholder: "First Name", onChange: handleSignUpChange }} label="First Name" />
							</div>
							<div className="u-mgn-btm-sm">
								<Input className="p__1" inputProps={{ type: "text", name: "last_name", placeholder: "Last Name", onChange: handleSignUpChange }} label="Last Name" />
							</div>
							<div className="u-mgn-btm-sm">
								<Input className="p__1" inputProps={{ type: "password", name: "password", placeholder: "Password", onChange: handleSignUpChange }} label="Password" />
							</div>
							<div className="u-mgn-btm-sm">
								<p className="p__2 u-mgn-btm-sm">How did you hear about us?</p>
								<select onChange={handleHDYH} className="input__select" name="howDidYouHear" id="howDidYouHear" required defaultValue="">
								<option value="" disabled>Select an option</option>
									<option value="LinkedIn">LinkedIn</option>
									<option value="Google">Google</option>
									<option value="Facebook">Facebook</option>
									<option value="Reddit">Reddit</option>
									<option value="TikTok">TikTok</option>
									<option value="TikTok">Instagram</option>
									<option value="Referral">Referral</option>
									<option value="Other">Other</option>
								</select>
							</div>
							<div className="u-mgn-btm-sm">
								<Input className="p__1" inputProps={{ type: "text", name: "referrer", placeholder: "Referrer username", onChange: handleSignUpChange }} label="Referral username (optional)" />
							</div>

							{signupPending ?
								<ClipLoader color="#36d7b7" />
								:
								<Button buttonProps={{ onClick: signup }}>Sign Up</Button>
							}

						</form>
					</div>
				</ModalBody>
			</Modal>

			<Modal isOpen={forgotPassModalOpen} toggle={() => toggleForgotPassModal(!forgotPassModalOpen)} onClosed={() => { toggleCursor && toggleCursor(true) }} onOpened={() => { toggleCursor && toggleCursor(false) }}>
				<ModalHeader>
					Reset Password
					<div className="modal__close" onClick={() => toggleForgotPassModal(false)}>&#x2715;</div>
				</ModalHeader>
				<ModalBody>
					<div className="u-pad-md">
						<p>A link to reset your password will be sent to your email address.</p>
						<form onSubmit={forgotPass}>

							<div className="u-mgn-btm-md">
								<Input className="p__1" inputProps={{ type: "email", name: "email", placeholder: "Email", onChange: handleResetEmailChange }} />
							</div>

							<Button buttonProps={{ onClick: forgotPass }}>Send Reset Email</Button>
						</form>
					</div>
				</ModalBody>
			</Modal>

			<Modal modalClassName="main__nav__modal" className="modal__nav" isOpen={navModal} toggle={() => toggleNav(!navModal)} fullscreen onClosed={() => { toggleCursor && toggleCursor(true) }} onOpened={() => { toggleCursor && toggleCursor(false) }}>
				<ModalHeader>
					<div className="main__nav__modal__auth">

						{!isAuthenticated &&
							<div className="u-hover-pointer u-mgn-right-sm" onClick={() => { toggleNav(false); setModalState({ ...modalState, modalOpen: true }) }}>
								<p className="modal__nav__item">
									Sign In
								</p>
							</div>
						}
						{!isAuthenticated &&
							<div className="u-hover-pointer" onClick={() => { toggleNav(false); toggleSignUpModal(true); }}>
								<p className="modal__nav__item">
									Sign Up
								</p>
							</div>
						}

						{isAuthenticated &&
							<div className="u-hover-pointer u-full-width u-flex u-flex-justify-end" onClick={logout}>
								<p className="modal__nav__item">
									Sign Out
								</p>
							</div>
						}
					</div>
					<div className="modal__close" onClick={() => toggleNav(!navModal)}>&#x2715;</div>

				</ModalHeader>
				<ModalBody className="main__nav__modal__body">
					{isAuthenticated ?
						<Link to="/showrunner/home" className="navbar__link">
							<p className="modal__nav__item">
								Dashboard
							</p>
						</Link> : null}

					<Link to="/events" className="navbar__link">
						<p className="modal__nav__item">
							Browse Events
						</p>
					</Link>

					<Link to="/workwithus" className="navbar__link">
						<p className="modal__nav__item">
							About Us
						</p>
					</Link>
					<Link to="/willcall" className="navbar__link">
						<p className="modal__nav__item">
							Will Call Services
						</p>
					</Link>

				</ModalBody>
				<ModalFooter>
					<div className="">
						<Footer className={"footer__white"} />
					</div>
				</ModalFooter>
			</Modal>
		</>
	)
}

