import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import API from "../utils/API";
import Navbar from "../components/Navbar";
import LandingCarousel from "../components/LandingCarousel";
import ClipLoader from "react-spinners/ClipLoader";
import EventCard from "../components/cards/EventCard";

export default function Landing() {
  const [shows, setShows] = useState([]);

  useEffect(() => {
    let params = {
      is_finished: false,
      published: true,
    };
    params = API.objectToParams(params);
    API.getShows(params).then((res) => {
      console.log("API.getShows", res.data);
      setShows(res.data);
    });
  }, []);

  return (
    <div className="landing">
      <Navbar />

      <div className="landing__events">
        {shows.length > 0 ? (
          <>
            <div className="landing__events__slider u-mgn-btm-md">
              <LandingCarousel shows={shows} /> {/* Pass shows to LandingCarousel */}
            </div>

            <div className="landing__events__wrap">
              {shows.map((show) => (
                <div key={show.id}>
                  <EventCard show={show} />
                </div>
              ))}
            </div>
            <div className="u-mgn-top-lg">
              <Footer />
            </div>
          </>
        ) : (
          <div className="u-flex-all-center u-full-width">
            <ClipLoader
              color="#0029FF"
              loading={true}
              css={""}
              size={150}
              speedMultiplier={0.3}
            />
          </div>
        )}
      </div>
    </div>
  );
}