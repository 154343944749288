import React, { useEffect, useState } from "react";
import Collapsible from "../../components/Collapsible";
import utils from "../../utils/utils";
import API from "../../utils/API";
import StripeSignUp from "./StripeSignUp";
import { useAuth } from '../../utils/authContext';
import ClipLoader from "react-spinners/ClipLoader";
import Button from "../../components/buttons/Button";
import { VictoryChart, VictoryLine, VictoryAxis, VictoryTooltip, VictoryTheme, VictoryVoronoiContainer, VictoryBar } from 'victory';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import moment from 'moment';

const MAX_TICKS = 7;

const flashyTheme = {
	axis: {
		style: {
			axis: {
				stroke: "white", // Color of the axis line
				strokeWidth: 2,
			},
			axisLabel: {
				fontSize: 20,
				padding: 30,
				fill: "#0029FF", // Color of the axis label
			},
			grid: {
				stroke: "none", // Remove grid lines
			},
			ticks: {
				stroke: "transparent",
				size: 5,
				strokeWidth: 2,
			},
			tickLabels: {
				fontSize: 15,
				padding: 5,
				fill: "#A2A2A2", // Color of the tick labels
			},
		},
	},
	line: {
		style: {
			data: {
				strokeWidth: 3,
				strokeLinecap: "round",
			},
			labels: {
				fontSize: 15,
				fill: "#0029FF",
			},
		},
	},

};


export default function Financial(props) {
	const { auth, showrunner, isAuthenticated, loading } = useAuth();

	const [finances, setFinances] = useState({})
	const [hasProds, setHasProds] = useState(false)
	const [stripeAcctLink, setStripeAcctLink] = useState(false);
	const [salesChart, setSalesChart] = useState([]);
	const [salesTotal, setSalesTotal] = useState(0);
	const [salesChartLabels, setSalesChartLabels] = useState([]);
	const [ticketTotal, setTicketTotal] = useState(0);
	const [ticketsChart, setTicketsChart] = useState([]);
	const [ticketChartLabels, setTicketChartLabels] = useState([]);
	const [events, setEvents] = useState([]);

	const [startDate, setStartDate] = useState(moment().subtract(1, 'week'));
	const [endDate, setEndDate] = useState(moment());
	const [focusedInput, setFocusedInput] = useState(null);
	const [timeRange, setTimeRange] = useState({ starte_data: "", endDate: "" })
	const [allTime, setAllTime] = useState(true);
	const [selectedEvent, setSelectedEvent] = useState(0);
	const [dataLoading, setDataLoading] = useState(true);


	useEffect(() => {
		if (!isAuthenticated && !loading) {
			window.location.href = "/"
		}
		if (!isAuthenticated) return;

		// setInterval(() => {
		// 	resetFinancials();
		// }, 120000);
		if (!showrunner.stripe_connect_id) return;
		API.getStripeConnectDash(showrunner.stripe_connect_id)
			.then(res => {
				const { link, error } = res.data
				if (link) {
					setStripeAcctLink(link)
					setDataLoading(false)
					// display link
				} else if (error) {
					// display error
					API.getConnectAccountLink(props.showrunner.stripe_connect_id)
						.then(res2 => {
							// setDataLoading(false)
						})
				}
			})
			.catch(e => {
				console.error("Error getting stripe connect dash", e)
			})

		if (!showrunner) return;
		const params = API.objectToParams({ showrunner_id: showrunner.id })
		if (showrunner.id) {

			API.getShows(params)
				.then(res => {
					setEvents(res.data);
					resetFinancials();
				})
		}
	}, [isAuthenticated])

	useEffect(() => {
		if (!showrunner) return;
		resetFinancials();
	}, [allTime, endDate, selectedEvent])


	function resetFinancials() {
		setDataLoading(true);
		const filters = {};
		// if (startDate && !endDate) {
		// 	utils.sendAlert("Please select an end date", "error")
		// }
		// if (!startDate && endDate) {
		// 	utils.sendAlert("Please select a start date", "error")
		// }

		if (startDate && endDate && !allTime) {
			filters["start_date"] = startDate.format('YYYY-MM-DD');
			filters["end_date"] = endDate.format('YYYY-MM-DD');
		}

		if (selectedEvent > 0) {
			filters["show_id"] = selectedEvent
		}
		API.getFinancialData(showrunner.id, filters)
			.then(res => {

				const salesChartData = res.data.financial_chart.map(item => ({
					x: new Date(item.date_created__date),
					y: item.total_revenue
				}));

				let startDate, endDate;
				if (filters.start_date && filters.end_date) {
					startDate = new Date(filters.start_date);
					endDate = new Date(filters.end_date);
				} else {
					startDate = salesChartData.length ? new Date(salesChartData[0].x) : new Date();
					endDate = salesChartData.length ? new Date(salesChartData[salesChartData.length - 1].x) : new Date();
				}
				const { aggregatedData: aggregatedSalesData, labels: salesLabels } = aggregateData(salesChartData, startDate, endDate);
				setSalesChart(aggregatedSalesData);
				setSalesChartLabels(salesLabels);

				const ticketChartData = res.data.ticket_chart.map(item => ({
					x: new Date(item.order__date_created__date),
					y: item.tickets_sold
				}));

				if (filters.start_date && filters.end_date) {
					startDate = new Date(filters.start_date);
					endDate = new Date(filters.end_date);
				} else {
					startDate = ticketChartData.length ? new Date(ticketChartData[0].x) : new Date();
					endDate = ticketChartData.length ? new Date(ticketChartData[ticketChartData.length - 1].x) : new Date();
				}
				const { aggregatedData: aggregatedTicketData, labels: ticketLabels } = aggregateData(ticketChartData, startDate, endDate);
				setTicketsChart(aggregatedTicketData);
				setTicketChartLabels(ticketLabels);

				setTicketTotal(res.data.total_tickets);
				setSalesTotal(res.data.total_revenue);
				setDataLoading(false);
			});
	}

	function aggregateData(data, startDate, endDate) {
		const timeSpanDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
		let interval, labelFormat;

		if (timeSpanDays <= 7) {
			// Daily intervals
			interval = 'daily';
			labelFormat = (date) => `${date.getMonth() + 1}/${date.getDate()}`;
		} else if (timeSpanDays <= 30) {
			// Weekly intervals
			interval = 'weekly';
			labelFormat = (date) => `${date.getMonth() + 1}/${date.getDate()}`;
		} else if (timeSpanDays <= 365) {
			// Monthly intervals
			interval = 'monthly';
			labelFormat = (date) => `${date.getMonth() + 1}/${date.getDate()}`;
		} else {
			// Quarterly intervals
			interval = 'quarterly';
			labelFormat = (date) => `Q${Math.floor(date.getMonth() / 3) + 1} ${date.getFullYear().toString().slice(-2)}`;
		}

		const aggregatedData = aggregateByInterval(data, interval);
		const labels = aggregatedData.map((item) => labelFormat(new Date(item.x)));

		return { aggregatedData, labels };
	}

	const aggregateByInterval = (data, interval) => {
		if (data.length <= MAX_TICKS) {
			return data;
		}

		const intervalSize = Math.ceil(data.length / MAX_TICKS);
		let aggregatedData = [];

		for (let i = 0; i < data.length; i += intervalSize) {
			const chunk = data.slice(i, i + intervalSize);
			const totalValue = chunk.reduce((sum, item) => sum + item.y, 0);
			const averageDate = new Date(chunk.reduce((sum, item) => sum + item.x.getTime(), 0) / chunk.length);
			aggregatedData.push({ x: averageDate, y: totalValue });
		}

		return aggregatedData;
	};

	function handleEventChange(e) {
		setSelectedEvent(e.target.value);
	}



	return (
		<>
			{!dataLoading ?
				<div className="showrunner__finances">
					{stripeAcctLink ?
						<>
							<div className="u-flex u-flex-justify-between u-flex-align-center" >

								<h3 className="heading__3">Finances</h3>
								<a href={stripeAcctLink} target="_blank">
									<Button>Go to Stripe</Button>
								</a>
							</div>
							<div className="showrunner__filters u-flex-justify-around">
								<div className="showrunner__filters__section showrunner__filters__section--f1">
									{/* <h5 className="heading__5">Event:</h5> */}
									<select value={selectedEvent} onChange={handleEventChange}>
										<option value={0}>All events</option>
										{events.map(ev => (
											<option key={ev.id} value={ev.id}>{ev.production.name}, {utils.formatDateTime(ev.date)[0]} at {utils.formatDateTime(ev.date)[1]}</option>
										))}
									</select>
								</div>
								<div className="showrunner__filters__section showrunner__filters__section--f2">
									{allTime ?
										<div className="select u-hover-pointer u-flex u-flex-align-center" onClick={() => setAllTime(false)}> <span>All Time</span>  <span className="caret"></span></div>
										:
										<div className="date-range">
											<DateRangePicker
												startDate={startDate}
												startDateId="start_date_id"
												endDate={endDate}
												endDateId="end_date_id"
												onDatesChange={({ startDate, endDate }) => {
													setStartDate(startDate);
													setEndDate(endDate);
												}}
												focusedInput={focusedInput}
												onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
												numberOfMonths={1}
												isOutsideRange={() => false}
												displayFormat="MM/DD/YYYY"

											/>
											<Button buttonProps={{ onClick: () => setAllTime(true) }} sm={true} className="showrunner__filters--reset">Reset</Button>
										</div>
									}
								</div>
							</div>

							<div className="dash-home__charts">
								<div className="dash-home__chart">
									<p className="dash-home__chart__title u-mgn-btm`-sm">Sales:</p>
									<p className="dash-home__chart__total">{utils.formatFinancial(salesTotal)}</p>
									{salesChart.length > 0 && (
										<VictoryChart
											theme={flashyTheme}
											scale={{ x: "time" }}
											containerComponent={<VictoryVoronoiContainer />}
										>
											<VictoryAxis
												tickValues={salesChart.map(d => d.x)}
												tickFormat={(x, index) => salesChartLabels[index]}
												style={{
													grid: {
														stroke: "none", // Hide vertical grid lines
													},
											}}
												/>
											<VictoryAxis
												dependentAxis
												tickFormat={(y) => `$${y}`}
												style={{
													tickLabels: {
														fontSize: 12,
													},
													grid: {
														stroke: "rgba(162, 162, 162, 0.2)", // Show horizontal grid lines
														strokeDasharray: "0",
												},
				
												}}

											/>
											<VictoryLine
												data={salesChart}
												x="x"
												y="y"
												style={{ data: { stroke: "#B4BCFF" } }}
												labels={({ datum }) => `$${datum.y.toFixed(2)}`}
												labelComponent={<VictoryTooltip />}
											/>
										</VictoryChart>
									)}
								</div>
								<div className="dash-home__chart">
									<p className="dash-home__chart__title u-mgn-btm`-sm">Tickets sold:</p>
									<p className="dash-home__chart__total">{ticketTotal}</p>
									{ticketsChart.length > 0 && (
										<VictoryChart
											theme={flashyTheme}
											scale={{ x: "time" }}
											domainPadding={{ x: 20 }}
											containerComponent={<VictoryVoronoiContainer />}
										>
											<VictoryAxis
												tickValues={ticketsChart.map(d => d.x)}
												tickFormat={(x, index) => ticketChartLabels[index]}
											/>
											<VictoryAxis
												dependentAxis
												tickFormat={(y) => `${y}`}
												style={{
													tickLabels: {
														fontSize: 12,
													},
													grid: {
														stroke: "rgba(162, 162, 162, 0.2)", // Show horizontal grid lines
														strokeDasharray: "0",
												},
												}}
											/>
											<VictoryBar
												data={ticketsChart}
												x="x"
												y="y"
												style={{ data: { fill: "#FDB9EE" } }}
												labels={({ datum }) => `${datum.y}`}
												labelComponent={<VictoryTooltip />}
											/>
										</VictoryChart>
									)}
								</div>
							</div>
						</>
						:
						<StripeSignUp showrunner={showrunner} userID={auth.id} />

					}

				</div> :
				<div className="u-flex-all-center u-full-width">
					<ClipLoader color="#0029FF" loading={true} css={""} size={150} speedMultiplier={.3} />
				</div>
			}
		</>
	)
} 